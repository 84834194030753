// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              blogPosts: field.required("blogPosts", Json_Decode$JsonCombinators.array(BlogPost.Analytics.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalBlogPosts: field.required("totalBlogPosts", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(pageNum, query, sortBy, categories) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "ThisWeek" ? "&sort_by=this_week&sort_direction=" + toString(sortBy.VAL) : (
      variant === "Title" ? "&sort_by=title&sort_direction=" + toString(sortBy.VAL) : (
          variant === "TotalViews" ? "&sort_by=ahoy_events_count&sort_direction=" + toString(sortBy.VAL) : (
              variant === "LastWeek" ? "&sort_by=last_week&sort_direction=" + toString(sortBy.VAL) : "&sort_by=blog_post_category_title&sort_direction=" + toString(sortBy.VAL)
            )
        )
    );
  var categories$1 = Belt_List.reduce(categories, "", (function (acc, categoryId) {
          return acc + ("&blog_post_category_ids" + (encodeURIComponent("[]") + ("=" + ID.toString(categoryId))));
        }));
  return Rest.$$fetch("/dashboard/blog_posts/analytics?" + (page + (query$1 + (sortBy$1 + categories$1))), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
