// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CreateBlogPostRequest from "./requests/CreateBlogPostRequest.res.js";
import * as FetchBlogPostsRequest from "./requests/FetchBlogPostsRequest.res.js";
import * as UpdateBlogPostRequest from "./requests/UpdateBlogPostRequest.res.js";
import * as DestroyBlogPostRequest from "./requests/DestroyBlogPostRequest.res.js";
import * as PublishBlogPostRequest from "./requests/PublishBlogPostRequest.res.js";
import * as UnpublishBlogPostRequest from "./requests/UnpublishBlogPostRequest.res.js";
import * as FetchBlogPostsAnalyticsRequest from "./requests/FetchBlogPostsAnalyticsRequest.res.js";
import * as FetchBlogPostsDashboardRequest from "./requests/FetchBlogPostsDashboardRequest.res.js";
import * as PublishBlogPostsInBatchRequest from "./requests/PublishBlogPostsInBatchRequest.res.js";
import * as SubscribeBlogNewsletterRequest from "../requests/SubscribeBlogNewsletterRequest.res.js";
import * as UnpublishBlogPostsInBatchRequest from "./requests/UnpublishBlogPostsInBatchRequest.res.js";

var fetchBlogPostsDashboard = FetchBlogPostsDashboardRequest.exec;

var publishBlogPost = PublishBlogPostRequest.exec;

var unpublishBlogPost = UnpublishBlogPostRequest.exec;

var fetchBlogPosts = FetchBlogPostsRequest.exec;

var fetchBlogPostsAnalytics = FetchBlogPostsAnalyticsRequest.exec;

var subscribeBlogNewsletter = SubscribeBlogNewsletterRequest.exec;

var publishBlogPostsInBatch = PublishBlogPostsInBatchRequest.exec;

var unpublishBlogPostsInBatch = UnpublishBlogPostsInBatchRequest.exec;

var destroyBlogPost = DestroyBlogPostRequest.exec;

var create = CreateBlogPostRequest.exec;

var update = UpdateBlogPostRequest.exec;

export {
  fetchBlogPostsDashboard ,
  publishBlogPost ,
  unpublishBlogPost ,
  fetchBlogPosts ,
  fetchBlogPostsAnalytics ,
  subscribeBlogNewsletter ,
  publishBlogPostsInBatch ,
  unpublishBlogPostsInBatch ,
  destroyBlogPost ,
  create ,
  update ,
}
/* CreateBlogPostRequest Not a pure module */
