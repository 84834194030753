// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as BlogPost from "../../../models/BlogPost.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              blogPosts: field.required("blogPosts", Json_Decode$JsonCombinators.array(BlogPost.Simple.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalBlogPosts: field.required("totalBlogPosts", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(categoryId, pageNum, param) {
  var url = "/blog_posts?page=" + (String(pageNum) + ("&blog_post_category_id=" + ID.toString(categoryId)));
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
