// Generated by ReScript, PLEASE EDIT WITH CARE

import * as EmailValidator from "email-validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty() {
  return {
          email: ""
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "email",
                input.email
              ]]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

var ValidationResult = {};

function email(param) {
  var email$1 = param.email;
  if (email$1 === "") {
    return {
            TAG: "Error",
            _0: "This field can't be empty"
          };
  } else {
    var result = EmailValidator.validate(email$1);
    if (result) {
      return {
              TAG: "Ok",
              _0: undefined
            };
    } else {
      return {
              TAG: "Error",
              _0: "This field should be valid email"
            };
    }
  }
}

function all(state) {
  return {
          email: email(state)
        };
}

function valid(result) {
  if (result.email.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  email: email,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationResult ,
  Validate ,
}
/* email-validator Not a pure module */
